import { template as template_33ae0bbe0ec9473c816721b623be4f74 } from "@ember/template-compiler";
export default template_33ae0bbe0ec9473c816721b623be4f74(`
  <svg width="98" height="132" viewBox="0 0 98 132" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.9">
      <path
        d="M95.755 6.48181V69.0846C95.755 88.3625 86.8309 106.722 71.2638 118.065C64.9181 122.691 57.5375 126.391 48.9966 129.076C48.7692 129.002 48.5489 128.936 48.3287 128.863C40.0671 126.193 32.9067 122.567 26.7329 118.065C11.1632 106.722 2.24121 88.3625 2.24121 69.0846V6.48181C11.7211 22.2822 39.8486 20.2144 48.9966 2.92896C58.1481 20.2144 86.274 22.2822 95.755 6.48181Z"
        fill="#67AB92"
      />
      <path
        d="M34.7979 120.614C32.4226 119.284 30.1634 117.84 28.0288 116.282C25.7748 114.64 23.661 112.837 21.6943 110.896C21.6424 110.839 21.587 110.784 21.5316 110.735C10.7144 99.9599 4.44531 84.9293 4.44531 69.0819V12.6147C5.11045 13.2234 5.81419 13.7956 6.55307 14.328V69.0836C6.55307 88.3627 15.474 106.721 31.0429 118.063C32.2564 118.949 33.5064 119.799 34.7979 120.614Z"
        fill="#B9D8CD"
      />
      <path
        d="M4.44558 12.6137V69.0809C4.44558 87.8756 13.2615 105.519 28.0288 116.282C34.085 120.696 41.1371 124.218 48.9993 126.759C56.8614 124.218 63.9135 120.696 69.9698 116.282C84.7379 105.519 93.5526 87.8756 93.5526 69.0809V12.6137C88.6077 17.1384 81.5826 19.605 73.7187 19.3936C63.513 19.1189 54.3628 14.4875 48.9993 7.08421C43.6376 14.4875 34.4855 19.1189 24.2781 19.3936C16.4387 19.6014 9.39199 17.1384 4.44558 12.6137ZM48.9993 131.28C48.7774 131.28 48.5555 131.246 48.3408 131.179C39.7552 128.481 32.05 124.67 25.4363 119.851C9.53414 108.261 0.0400391 89.2815 0.0400391 69.0809V6.48248C0.0400391 5.49043 0.700147 4.62125 1.65324 4.35562C2.60759 4.0918 3.62021 4.49657 4.1304 5.34406C7.89387 11.6162 15.4015 15.2067 24.159 14.9808C34.15 14.7116 42.9232 9.69715 47.0527 1.89267C47.4352 1.17168 48.1838 0.71991 48.9993 0.71991C49.8165 0.71991 50.5652 1.17168 50.9477 1.89267C55.0754 9.69715 63.8486 14.7116 73.8377 14.9808C82.6181 15.2248 90.105 11.6162 93.8683 5.34406C94.3789 4.49657 95.3892 4.0918 96.3453 4.35562C97.2997 4.62125 97.96 5.49043 97.96 6.48248V69.0809C97.96 89.2815 88.4651 108.261 72.5623 119.851C65.9503 124.668 58.2433 128.481 49.6596 131.179C49.4449 131.246 49.223 131.28 48.9993 131.28Z"
        fill="#371A45"
      />
      <path
        d="M48.9995 114.289C43.7775 112.292 39.062 109.77 34.9413 106.767C23.2043 98.2121 16.1943 84.1244 16.1943 69.082V30.5973C18.5204 30.9795 20.9182 31.1764 23.3709 31.1764C32.7652 31.1748 41.7563 28.2691 48.9995 23.2221C56.2444 28.2691 65.2355 31.1764 74.6298 31.1764C77.0825 31.1764 79.4819 30.9795 81.8047 30.5973V69.082C81.8047 84.1244 74.7964 98.2121 63.0577 106.767C58.937 109.77 54.2232 112.292 48.9995 114.289Z"
        fill="#FEDA24"
      />
      <path
        d="M47.1617 111.558C47.1601 111.558 47.1585 111.556 47.1553 111.555C47.1585 111.553 47.1601 111.553 47.1617 111.552V111.558ZM53.308 110.432V107.964C53.8918 107.575 54.4643 107.174 55.0255 106.764C66.7618 98.2113 73.7685 84.1251 73.7685 69.0822V63.488H79.9679V69.0806C79.9679 83.5372 73.2426 97.0693 61.9759 105.278C59.3418 107.199 56.4391 108.923 53.308 110.432ZM79.9679 59.809H73.7685V33.0059C74.0531 33.0107 74.3394 33.0139 74.6272 33.0139H74.6288C76.4203 33.0139 78.2086 32.9108 79.9679 32.7079V59.809Z"
        fill="#C2A02E"
      />
      <path
        d="M50.8358 109.533V63.4895H53.3093V107.966C52.5045 108.507 51.6817 109.029 50.8358 109.533ZM23.7708 91.3651C20.0663 84.7057 18.0322 77.0341 18.0322 69.0824V63.4895H19.8672V72.394C19.8672 79.0353 21.2331 85.4903 23.7708 91.3651ZM53.3093 59.8119H50.8358V26.5791C51.6455 27.0555 52.47 27.5055 53.3093 27.9308V59.8119ZM19.8672 59.8119H18.0322V32.7094C19.7898 32.9122 21.5787 33.0144 23.3725 33.0144C31.7425 33.0144 40.068 30.7478 47.1626 26.5791V29.7819C41.0818 32.8067 34.1765 34.4847 27.0407 34.4847C24.5887 34.4847 22.1958 34.2935 19.8672 33.9094V59.8119Z"
        fill="#FFF4BD"
      />
      <path
        d="M50.834 111.558V109.531C51.6796 109.026 52.5044 108.505 53.3084 107.965V110.431C52.4992 110.822 51.6731 111.198 50.834 111.558Z"
        fill="#EDE2C0"
      />
      <path
        d="M18.0303 32.7067V69.0802C18.0303 83.5361 24.7547 97.0691 36.0187 105.277C39.8299 108.052 44.1915 110.417 48.9978 112.315C53.8042 110.417 58.1674 108.052 61.9752 105.277C73.2409 97.0675 79.9671 83.5361 79.9671 69.0802V32.7067C78.2067 32.9101 76.4194 33.0126 74.6288 33.0126H74.6254C65.5212 33.0126 56.4725 30.3316 48.9978 25.4384C41.5232 30.3299 32.4744 33.0126 23.3703 33.0126C21.5763 33.0126 19.7873 32.9101 18.0303 32.7067ZM48.9978 116.126C48.7763 116.126 48.5548 116.084 48.3433 116.005C42.9765 113.951 38.103 111.344 33.8606 108.251C21.6468 99.3518 14.3584 84.7093 14.3584 69.0802V30.5955C14.3584 30.0559 14.5933 29.5432 15.0045 29.1936C15.4157 28.844 15.9577 28.6944 16.4897 28.7818C18.7284 29.1482 21.0426 29.3348 23.3703 29.3348C32.1623 29.3348 40.8905 26.6268 47.949 21.7118C48.58 21.2714 49.4157 21.2714 50.045 21.7118C57.1051 26.6268 65.8334 29.3348 74.6254 29.3348H74.6288C76.9497 29.3348 79.2656 29.1482 81.506 28.7818C82.0379 28.6944 82.5817 28.844 82.9928 29.1936C83.4023 29.5432 83.6389 30.0559 83.6389 30.5955V69.0802C83.6389 84.7093 76.3489 99.3518 64.1367 108.251C59.8943 111.342 55.0208 113.951 49.654 116.005C49.4425 116.084 49.2193 116.126 48.9978 116.126Z"
        fill="#371A45"
      />
      <path
        d="M48.999 116.126C47.9841 116.126 47.1621 115.302 47.1621 114.286V23.2215C47.1621 22.2049 47.9841 21.3816 48.999 21.3816C50.0122 21.3816 50.8342 22.2049 50.8342 23.2215V114.286C50.8342 115.302 50.0122 116.126 48.999 116.126Z"
        fill="#371A45"
      />
      <path
        d="M81.8032 63.4885H16.1939C15.1796 63.4885 14.3584 62.666 14.3584 61.6501C14.3584 60.6342 15.1796 59.8101 16.1939 59.8101H81.8032C82.8175 59.8101 83.6402 60.6342 83.6402 61.6501C83.6402 62.666 82.8175 63.4885 81.8032 63.4885Z"
        fill="#371A45"
      />
      <path
        d="M63.1311 27.7893C58.4448 26.5111 53.9985 24.462 50.0695 21.7288C49.81 21.5414 49.4994 21.4211 49.1622 21.3911C49.1075 21.3858 49.0528 21.384 48.9981 21.384C48.9451 21.384 48.8886 21.3858 48.8339 21.3911C48.6574 21.407 48.488 21.4476 48.3291 21.5095V7.97228C48.5586 7.68234 48.781 7.3871 48.9981 7.08655C52.9519 12.5442 58.9637 16.4938 65.9393 18.3042L63.1311 27.7893Z"
        fill="#1C825D"
      />
      <path
        d="M65.9396 18.3025C58.965 16.493 52.9515 12.5436 48.9979 7.08665C48.7821 7.38674 48.5574 7.68146 48.3291 7.97262V4.1054C51.7906 11.1664 66.5727 16.1679 66.5727 16.1679L65.9396 18.3025Z"
        fill="#371A45"
      />
      <path
        d="M62.6066 29.5596C56.4737 28.7362 52.8821 26.893 50.8355 25.2867V23.2217C50.8355 22.605 50.5336 22.0606 50.0706 21.7279C53.9993 24.46 58.4464 26.5099 63.1317 27.7886L62.6066 29.5596ZM49.0003 21.3818C49.054 21.3818 49.1077 21.3852 49.1631 21.3902C49.1094 21.3852 49.0557 21.3818 49.0003 21.3818Z"
        fill="#371A45"
      />
      <path
        d="M50.8346 25.2871C48.8703 23.7446 48.3301 22.4189 48.3301 22.4189V21.5082C48.4894 21.4461 48.6572 21.4057 48.835 21.3906C48.8887 21.3856 48.9441 21.3822 48.9994 21.3822C49.0548 21.3822 49.1085 21.3856 49.1621 21.3906C49.4993 21.4192 49.8096 21.5401 50.0697 21.7283C50.5327 22.061 50.8346 22.6054 50.8346 23.2221V25.2871Z"
        fill="#371A45"
      />
      <path
        d="M48.9983 126.759C48.775 126.687 48.5517 126.613 48.3301 126.539V115.999C48.5326 116.081 48.756 116.126 48.9879 116.126C48.9897 116.126 48.9914 116.126 48.9914 116.126C48.9949 116.126 48.9966 116.127 48.9983 116.127C49.0053 116.127 49.0139 116.126 49.0208 116.126C49.026 116.126 49.0295 116.126 49.033 116.126H49.0347C49.0364 116.126 49.0416 116.126 49.0451 116.126H49.0468C49.0503 116.126 49.052 116.126 49.0555 116.126C49.0572 116.126 49.0572 116.126 49.0589 116.126C49.0607 116.126 49.0624 116.126 49.0641 116.126C49.0676 116.126 49.0693 116.124 49.0728 116.124C49.2719 116.117 49.464 116.075 49.6423 116.01C49.6458 116.01 49.6493 116.006 49.6545 116.004C55.0212 113.951 59.8946 111.343 64.1361 108.251C76.3498 99.3541 83.64 84.7102 83.64 69.0813V61.6492V30.5961C83.64 30.0568 83.4028 29.5436 82.9925 29.195C82.9319 29.143 82.8678 29.0962 82.8021 29.0528L93.3157 12.8274C93.3954 12.7581 93.475 12.687 93.5529 12.6142V69.0813C93.5529 87.8748 84.7358 105.52 69.9686 116.282C63.9128 120.695 56.8615 124.218 48.9983 126.759Z"
        fill="#1C825D"
      />
      <path
        d="M48.9965 129.073C48.7697 129 48.5499 128.934 48.3301 128.86V126.538C48.5516 126.611 48.7749 126.685 48.9982 126.758C56.861 124.217 63.9125 120.694 69.9696 116.281C84.7373 105.518 93.5525 87.873 93.5525 69.0798V12.6125C93.4757 12.6842 93.3955 12.7558 93.317 12.8257L94.8662 10.4353C94.8662 10.4353 95.1889 10.8337 95.7541 11.5326V69.0815C95.7541 69.4852 95.7506 69.8871 95.7437 70.289C95.5849 72.0154 93.7496 90.0433 84.3988 104.791C80.7963 109.831 76.3895 114.327 71.264 118.061C70.2138 118.826 69.1374 119.566 68.0314 120.28C68.0244 120.284 68.0174 120.287 68.0104 120.291C68.0104 120.291 68.0122 120.291 68.0157 120.289C62.4366 123.89 56.1195 126.834 48.9965 129.073Z"
        fill="#371A45"
      />
      <path
        d="M48.9987 116.126V116.124C49.0052 116.124 49.0134 116.124 49.0216 116.124C49.0134 116.124 49.0052 116.126 48.9987 116.126ZM49.6412 116.01C50.3374 115.748 50.8331 115.076 50.8331 114.287V113.771C66.416 106.947 78.3972 95.6881 81.8001 73.9063V63.4866H81.8033C82.8175 63.4866 83.6393 62.6634 83.6393 61.6476V69.0802C83.6393 84.7082 76.3492 99.3529 64.1366 108.251C59.8956 111.342 55.0219 113.95 49.6542 116.005C49.6493 116.005 49.646 116.008 49.6412 116.01ZM83.6393 61.6476C83.6393 60.6334 82.8175 59.8086 81.8033 59.8086H81.8001V30.5991L82.8028 29.0525C82.8681 29.0949 82.9317 29.1423 82.992 29.1929C83.4012 29.5424 83.6393 30.0553 83.6393 30.5958V61.6476Z"
        fill="#371A45"
      />
      <path
        d="M48.9991 116.126C48.9965 116.126 48.9952 116.124 48.9926 116.124C48.9913 116.124 48.99 116.124 48.9888 116.124C48.7563 116.124 48.5341 116.079 48.3301 115.999V114.818C49.1747 114.482 50.0091 114.132 50.8343 113.771V114.286C50.8343 115.075 50.3384 115.748 49.6423 116.009C49.464 116.075 49.2729 116.115 49.0727 116.123C49.0701 116.123 49.0675 116.124 49.065 116.124C49.0637 116.124 49.0611 116.124 49.0598 116.124C49.0585 116.124 49.0572 116.124 49.0559 116.124C49.0533 116.124 49.0507 116.124 49.0482 116.124C49.0469 116.124 49.0456 116.124 49.0456 116.124C49.0417 116.124 49.0378 116.124 49.0352 116.124H49.034C49.0301 116.124 49.0262 116.124 49.0223 116.124C49.0146 116.124 49.0068 116.124 48.9991 116.124V116.126Z"
        fill="#371A45"
      />
      <path
        d="M81.8042 63.4885H81.8027V59.8101H81.8042C82.8185 59.8101 83.6412 60.6342 83.6412 61.6501C83.6412 62.666 82.8185 63.4885 81.8042 63.4885Z"
        fill="#371A45"
      />
      <path
        d="M68.0166 120.291C68.0219 120.288 68.0254 120.284 68.0307 120.282C75.1298 116.7 80.4392 111.039 84.3992 104.793C91.5124 94.8505 95.5006 82.7906 95.7437 70.2896C95.7507 70.2031 95.7542 70.1572 95.7542 70.1572V8.20081L97.9597 7.65912V9.79055V32.8517V37.2646V69.0809C97.9597 74.3019 97.3238 79.4435 96.1083 84.3962C95.7437 85.8854 95.3262 87.3552 94.8559 88.8091C90.9769 100.814 83.6047 111.461 73.5603 119.107C70.3313 119.993 68.1417 120.275 68.0166 120.291Z"
        fill="#371A45"
      />
      <path
        d="M68.0098 120.293C68.0176 120.29 68.0238 120.287 68.03 120.283C68.0254 120.286 68.0222 120.29 68.016 120.292C68.0129 120.293 68.0098 120.293 68.0098 120.293ZM84.3992 104.794C93.7485 90.046 95.5853 72.0182 95.7428 70.2924C95.4996 82.793 91.5125 94.8517 84.3992 104.794Z"
        fill="#371A45"
      />
    </g>
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
