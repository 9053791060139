import { template as template_ddb2964f7c7545d6b6c3ae43ef035957 } from "@ember/template-compiler";
import Footer from '../footer';
import NavbarHeader from '../navbar-header';
export default template_ddb2964f7c7545d6b6c3ae43ef035957(`
  <NavbarHeader />
  {{yield}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
