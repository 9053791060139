import { template as template_0961672dbbd1442e8811fc090c423fb5 } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import textWithMultipleLang from '../helpers/text-with-multiple-lang.js';
export default template_0961672dbbd1442e8811fc090c423fb5(`
  {{#each @banners as |banner|}}
    <PixBannerAlert @type={{banner.severity}} @canCloseBanner={{false}}>
      {{textWithMultipleLang banner.message}}
    </PixBannerAlert>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
