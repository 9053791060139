import { template as template_c7c5c540a86d46afbab1e1129d4e343b } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_c7c5c540a86d46afbab1e1129d4e343b(`
  <ol class="module-objectives">
    {{#each @objectives as |objective|}}
      <li class="module-objectives__item">{{htmlUnsafe objective}}</li>
    {{/each}}
  </ol>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
