import { template as template_d9202b80364149a8a896163e0f4a23be } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { t } from 'ember-intl';
export default template_d9202b80364149a8a896163e0f4a23be(`
  <PixBannerAlert @type="info">
    {{t "pages.modulix.beta-banner"}}
  </PixBannerAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
