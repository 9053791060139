import { template as template_4a4bbb4ed3334b0bbd21dd27493d86db } from "@ember/template-compiler";
export default template_4a4bbb4ed3334b0bbd21dd27493d86db(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
