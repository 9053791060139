import { template as template_34b2a228d99a4f799f9d642f2e13eec1 } from "@ember/template-compiler";
import Component from '@glimmer/component';
export default class ModulixFeedback extends Component {
    get type() {
        return this.args.answerIsValid ? 'success' : 'error';
    }
    static{
        template_34b2a228d99a4f799f9d642f2e13eec1(`
    <div class="feedback feedback--{{this.type}}">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
