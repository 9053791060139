import { template as template_196e6411dc2f4f30a3c37b231457808e } from "@ember/template-compiler";
import Footer from '../footer';
import NavbarHeader from '../navbar-header';
export default template_196e6411dc2f4f30a3c37b231457808e(`
  <NavbarHeader />
  {{yield}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
