import { template as template_fadf8574c9684332908f573fd14fa2c4 } from "@ember/template-compiler";
export default template_fadf8574c9684332908f573fd14fa2c4(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
