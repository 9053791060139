import { template as template_b148ec6e6f4d44a98bd96f5cda3ffb0d } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_b148ec6e6f4d44a98bd96f5cda3ffb0d(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
