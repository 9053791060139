import { template as template_bcec729011e74aa68eb1336e8c7e0574 } from "@ember/template-compiler";
export default template_bcec729011e74aa68eb1336e8c7e0574(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
