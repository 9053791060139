import { template as template_5b67b4c42b6b4c11a2e00e919d6d41c2 } from "@ember/template-compiler";
import Component from '@glimmer/component';
export default class ModulixFeedback extends Component {
    get type() {
        return this.args.answerIsValid ? 'success' : 'error';
    }
    static{
        template_5b67b4c42b6b4c11a2e00e919d6d41c2(`
    <div class="feedback feedback--{{this.type}}">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
