import { template as template_4cc34f5f69884219934ee897075d1201 } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { t } from 'ember-intl';
export default template_4cc34f5f69884219934ee897075d1201(`
  <PixBannerAlert @type="info">
    {{t "pages.modulix.beta-banner"}}
  </PixBannerAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
